import { TagDto } from '@/generated/typing';

export const experienceService = {
    isInitialized: () => {
        return !!window.jimo;
    },
    openWidget: () => {
        if (!window.jimo) {
            return;
        }
        window.jimo.push(['do', 'widget:open', []]);
    },
    closeWidget: () => {
        if (!window.jimo) {
            return;
        }
        window.jimo.push(['do', 'widget:close', []]);
    },
    listenWidgetClose: (mode: 'on' | 'off', action: () => void) => {
        if (!window.jimo) {
            return;
        }
        window.jimo.push([mode, 'widget:closed', [action]]);
    },
    updateProductTags: (tags: TagDto[], productId: string) => {
        if (!window.jimo) {
            return;
        }

        if (!tags || !tags.length) {
            return;
        }

        const tagsToSend = tags.reduce((prev, curr) => {
            const { key, value } = curr;

            if (!key) return prev;

            if (prev[key]) {
                return {
                    ...prev,
                    [key]: prev[key].concat(';').concat(value),
                };
            }

            return {
                ...prev,
                [key]: value,
            };
        }, {} as Record<string, string>);

        window.jimo.push(['do', 'identify', [productId]]);
        window.jimo.push(['set', 'user:attributes', [tagsToSend]]);
    },
};
