import { forwardRef } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import InputFieldBase, { InputFieldBaseProps } from './InputFieldBase';
import { InputFieldEnum, validateInput } from '@/utils/input-field-utils';

export type InputFieldProps = {
    name: string;
    onChangeCallback?: InputFieldBaseProps['onChange'];
    isRequired?: boolean;
    forceOptional?: boolean;
    customValidator?: Function;
} & Omit<InputFieldBaseProps, 'value' | 'name' | 'onChange' | 'defaultValue'>;

const InputField = forwardRef(
    (
        {
            name,
            isRequired,
            forceOptional,
            customValidator,
            type,
            ...inputFieldProps
        }: InputFieldProps,
        forwardedRef,
    ) => {
        const { control } = useFormContext();

        return (
            <Controller
                name={name}
                control={control}
                rules={{
                    validate: (value: string | number) =>
                        validateInput({
                            type: type as InputFieldEnum,
                            value,
                            isRequired,
                            forceOptional,
                            customValidator,
                        }),
                }}
                render={({ field, fieldState: { error } }) => {
                    return (
                        <InputFieldBase
                            {...field}
                            {...inputFieldProps}
                            isRequired={isRequired}
                            type={type}
                            onChange={(value) => {
                                const e = {
                                    target: {
                                        value,
                                    },
                                };
                                field.onChange(e);
                                inputFieldProps.onChangeCallback?.(e);
                            }}
                            error={error?.message}
                            ref={forwardedRef}
                            forceOptional={forceOptional}
                        />
                    );
                }}
            />
        );
    },
);

export default InputField;
